import store from '@/store'

export const useAdmin = () => {}

export const useAdminUi = () => {
  const getFAQ = payload => store.dispatch('app-admin/getFAQ', payload)

  const getCMSPages = () => store.dispatch('app-admin/getCMSPages')

  const getCMSPagesByText = payload => store.dispatch('app-admin/getCMSPagesByText', payload)

  const updateCMSPage = payload => store.dispatch('app-admin/updateCMSPage', payload)

  const getFAQListAll = () => store.dispatch('app-admin/getFAQListAll')

  const getCategories = () => store.dispatch('app-admin/getCategories')

  const addCategories = payload => store.dispatch('app-admin/addCategories', payload)

  const updateCategories = payload => store.dispatch('app-admin/updateCategories', payload)

  const getCategoriesById = payload => store.dispatch('app-admin/getCategoriesById', payload)

  const deleteCategoriesById = payload => store.dispatch('app-admin/deleteCategoriesById', payload)

  const addFAQQuestions = payload => store.dispatch('app-admin/addFAQQuestions', payload)

  const updateFAQQuestions = payload => store.dispatch('app-admin/updateFAQQuestions', payload)

  const getFAQQuestionsById = payload => store.dispatch('app-admin/getFAQQuestionsById', payload)

  const deleteFAQQuestionsById = payload => store.dispatch('app-admin/deleteFAQQuestionsById', payload)

  const addFAQ = payload => store.dispatch('app-admin/addFAQ', payload)

  const updateFAQ = payload => store.dispatch('app-admin/updateFAQ', payload)

  const getFAQById = payload => store.dispatch('app-admin/getFAQById', payload)

  const deleteFAQById = payload => store.dispatch('app-admin/deleteFAQById', payload)

  const addBlog = payload => store.dispatch('app-admin/addBlog', payload)

  const updateBlog = payload => store.dispatch('app-admin/updateBlog', payload)

  const getBlogById = payload => store.dispatch('app-admin/getBlogById', payload)

  const deleteBlogById = payload => store.dispatch('app-admin/deleteBlogById', payload)

  const getAllBlogs = payload => store.dispatch('app-admin/getAllBlogs', payload)

  const getKnowledgeCategories = () => store.dispatch('app-admin/getKnowledgeCategories')

  const addKnowledgeCategories = payload => store.dispatch('app-admin/addKnowledgeCategories', payload)

  const updateKnowledgeCategories = payload => store.dispatch('app-admin/updateKnowledgeCategories', payload)

  const getKnowledgeCategoriesById = payload => store.dispatch('app-admin/getKnowledgeCategoriesById', payload)

  const deleteKnowledgeCategoriesById = payload => store.dispatch('app-admin/deleteKnowledgeCategoriesById', payload)

  const getKnowledgeList = () => store.dispatch('app-admin/getKnowledgeList')

  const addKnowledgeList = payload => store.dispatch('app-admin/addKnowledgeList', payload)

  const updateKnowledgeList = payload => store.dispatch('app-admin/updateKnowledgeList', payload)

  const getKnowledgeListById = payload => store.dispatch('app-admin/getKnowledgeListById', payload)

  const deleteKnowledgeListById = payload => store.dispatch('app-admin/deleteKnowledgeListById', payload)

  const getVariationById = payload => store.dispatch('app-admin/getVariationById', payload)

  const createVaraition = payload => store.dispatch('app-admin/createVaraition', payload)

  const updateVariation = payload => store.dispatch('app-admin/updateVariation', payload)

  const deleteVariation = payload => store.dispatch('app-admin/deleteVariation', payload)

  const adminStoreList = () => store.dispatch('app-admin/adminStoreList')

  const updateKnowledgeBaseQuestion = payload => store.dispatch('app-admin/updateKnowledgeBaseQuestion', payload)

  const createKnowledgeBaseQuestions = payload => store.dispatch('app-admin/createKnowledgeBaseQuestions', payload)

  const getKnowledgeBaseQuestionsById = payload => store.dispatch('app-admin/getKnowledgeBaseQuestionsById', payload)

  const deleteKnowledgeBaseQuestions = payload => store.dispatch('app-admin/deleteKnowledgeBaseQuestions', payload)

  const getAdminTranslations = payload => store.dispatch('app-admin/getAdminTranslations', payload)

  const saveAdminTranslations = payload => store.dispatch('app-admin/saveAdminTranslations', payload)

  return {
    getAdminTranslations,
    saveAdminTranslations,
    deleteKnowledgeBaseQuestions,
    getKnowledgeBaseQuestionsById,
    createKnowledgeBaseQuestions,
    updateKnowledgeBaseQuestion,
    adminStoreList,
    getVariationById,
    createVaraition,
    updateVariation,
    deleteVariation,
    getKnowledgeList,
    addKnowledgeList,
    updateKnowledgeList,
    getKnowledgeListById,
    deleteKnowledgeListById,
    getKnowledgeCategories,
    addKnowledgeCategories,
    updateKnowledgeCategories,
    getKnowledgeCategoriesById,
    deleteKnowledgeCategoriesById,
    getAllBlogs,
    deleteBlogById,
    getBlogById,
    updateBlog,
    addBlog,
    getFAQListAll,
    getFAQ,
    getCMSPages,
    getCMSPagesByText,
    updateCMSPage,
    getCategories,
    addCategories,
    updateCategories,
    getCategoriesById,
    deleteCategoriesById,
    addFAQQuestions,
    updateFAQQuestions,
    getFAQQuestionsById,
    deleteFAQQuestionsById,
    addFAQ,
    updateFAQ,
    getFAQById,
    deleteFAQById,
  }
}
