<template>
  <b-card class="admin-blog-index">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="blogRef"
          :button-label="$t('Add Blog')"
          :columns="blogColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/admin/blog/list'"
          @add-new-info="createBlog"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { showDangerNotification, showSuccessNotification, showErrorNotification, hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    GoodDataTable,
  },
  data() {
    return {
      blogColumns: [
        {
          label: 'Blog Title',
          field: 'title',
        },
        {
          label: 'Posted On',
          field: 'created_at',
          useRenderer: true,
          renderer: props => moment(props.created_at).format('YYYY-MM-DD HH:mm:ss A'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editBlog(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewBlog(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this blog?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deleteBlog(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    editBlog(props) {
      this.$router.push({
        path: `/admin/cmspages/blog/edit/${props.id}`,
      })
    },
    deleteBlog(props) {
      const { deleteBlogById } = useAdminUi()
      showLoader()
      deleteBlogById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.blogRef.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    viewBlog(props) {
      this.$router.push({
        path: `/admin/cmspages/blog/view/${props.id}`,
      })
    },
    createBlog() {
      this.$router.push({
        name: 'admin-cmspages-blog-create',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
